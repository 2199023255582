import React from "react";
import { graphql } from "gatsby";
import { Section } from "../components/ui";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Template(args) {
  const { data, location, pageContext } = args;
  const { group } = pageContext;
  const { t } = useTranslation("group-page");
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} />
      <Section backgroundColor="bg-helfa-light-green">
        <div className="text-left">
          <h2 className="text-2xl text-left my-2">{t("Lokale Gruppe")}</h2>
          <h1 className="text-4xl font-semibold text-left my-3 leading-relaxed">
            {group.name}
          </h1>
          <div>
            {t("Telegram Empfang")}:{" "}
            <a className="underline" href={group.receptionChannel}>
              {group.receptionChannel}
            </a>
          </div>
          <div>
            {t("PLZ")}: {group.postcode}
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
